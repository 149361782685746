import React from 'react'
import AboutMeSection from '../components/AboutMeSection'
import ProjectSection from '../components/ProjectsSections'
import LanguageSection from '../components/LanguageSection'

import { motion } from 'framer-motion'

import Transition from '../animation/transition'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { ContactMe } from '../components/ContactMe'
import Resume from '../components/Resume'

const Home = () => {

  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <motion.div>
        <AboutMeSection />
        <ProjectSection />
        <LanguageSection />
        <Resume />
        <ContactMe />
    </motion.div>
  )
}

export default Transition(Home)