import styled from "styled-components"
import * as theme from "./variables"

import {motion} from 'framer-motion'

export const ProjectContainer = styled(motion.div)`
    /* background-color: red; */
    margin: 13vh 0;
    height: fit-content;
    width: 100%;
`

export const FrameContainer = styled(motion.div)`
    width: 100%;
    height: 100vh;
    position: fixed;
    left: -100%;
    background-color: red;
    
z-index: 2000;
`

export const MainContainer = styled(motion.div)`
    margin: 13vh 0;
    height: fit-content;
    width: 100%;
`

export const ContentSection = styled(motion.div)`
    display: flex;
    width:100%;
    padding: 3rem 5rem;
    @media (max-width: 1000px) {
    padding: 3rem 2rem;
    flex-direction:column;
    }
`

export const FirstHalf = styled(motion.div)`
width:50%;
@media (max-width: 1000px) {
    width:100%;
}
`

export const Box = styled(motion.div)`
    margin-bottom: 3rem;
`

export const SecondHalf = styled(motion.div)`
width:50%;
margin-top: 3rem;
@media (max-width: 1000px) {
    width:100%;
}
`

export const Paragraph = styled(motion.p)`
color: ${theme.GRAY};
font-family: 'Helvetica-Medium';
font-size: 1.35rem;
opacity: .75;
margin-bottom: 1.5rem;
`

export const Title = styled(motion.h3)`
    font-size:.9rem;
    margin:.5rem 0;
    font-family:'Helvetica-Extra-Light';
    letter-spacing:.35rem;
    text-transform: uppercase;
    color: ${theme.SECONDARY};

`

export const Name = styled(motion.h1)`
    font-size:3rem;
    width: 80%;
    font-family:'Helvetica-ExtraBold';
    color: ${theme.PRIMARY};
    @media (max-width: 1000px) {
        font-size:2rem;
        width:90%;
    }

`

export const UList = styled.ul`

`

export const List = styled(motion.li)`
list-style: decimal-leading-zero;
margin-left: 2.2rem;
color: ${theme.PRIMARY};
margin-bottom: .2em;
font-family: 'Helvetica-Bold-Italic';
`

export const ImageSection = styled(motion.div)`
display: flex;
height: fit-content;
width: 100%;
flex-direction: column;
margin-top: 3rem;
padding: 0 5rem;
@media (max-width: 1000px) {
    padding: 0 1rem;
    }
`

export const Image = styled(motion.img)`
width: 100%;
margin-bottom: 5rem;
border-radius: 12px;
box-shadow: 0px 0px 30px -5px ${theme.LIGHT_GRAY};
@media (max-width: 1000px) {
    margin-bottom: 2rem;
    }
`