import { Route, Routes, useLocation } from "react-router-dom";
import { GlobalStyles, TopScrollButton } from "./styles/GlobalStyles";
import Home from "./pages/Home";
import Project from "./pages/Project";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import {FaArrowUp} from 'react-icons/fa'

function App() {
  const location = useLocation()

  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };
  
  window.addEventListener('scroll', toggleVisible);
  
  return (
    <div className="App">
      <TopScrollButton visible={visible} onClick={scrollToTop} whileHover={{y:-10, scale:1.2}} whileTap={{y:-10, scale:.9}}>
        <FaArrowUp size="20px" />
      </TopScrollButton>
      <AnimatePresence mode="wait">
      <Header />
        <Routes location={location} key={location.pathname} >
          <Route path="/" element={<Home/>} />
          <Route path="/projects/:projectId" element={<Project />} />
        </Routes>
      </AnimatePresence>
      <Footer/>
      <GlobalStyles />
    </div>
  );
}

export default App;
