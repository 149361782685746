export const slidein = {
    hidden:{
        y:"-100%",
    },
    show:{
        y:"0%",
        transition:{duration:1,ease:[0.33, 1, 0.68, 1]}
    },
    exit:{
        y:"100%",
        transition:{duration:1,ease:[0.68, -0.6, 0.32, 1.6]}
    }
}

export const slideLink1 = {
    hidden:{
        y:"-280%",
    },
    show:{
        y:"0%",
        transition:{duration:1, delay:.2, ease:[0.22, 1, 0.36, 1]}
    },
    exit:{
        y:"280%",
        transition:{duration:.3, delay:.2}
    }
}

export const slideLink2 = {
    hidden:{
        y:"-280%",
    },
    show:{
        y:"0%",
        transition:{duration:1, delay:.25,ease:[0.22, 1, 0.36, 1]}
    },
    exit:{
        y:"280%",
        transition:{duration:.3, delay:.25}
    }
}

export const slideLink3 = {
    hidden:{
        y:"-280%",
    },
    show:{
        y:"0%",
        transition:{duration:1, delay:.3,ease:[0.22, 1, 0.36, 1]}
    },
    exit:{
        y:"280%",
        transition:{duration:.3, delay:.3}
    }
}