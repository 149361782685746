import styled from 'styled-components'
import * as theme from './variables'
import { motion } from 'framer-motion'

export const ResumeDiv = styled(motion.div)`
    width: 100%;
    height: fit-content;
    padding:0 5rem;
    display: flex;
    margin-bottom: 3rem;
    flex-direction:column;
    @media (max-width: 1300px) {
        margin-bottom:2rem;
        height: fit-content;
        padding:0 2rem;
    }
    @media (max-width: 1010px) {
        margin-bottom:2rem;
        padding:0 1rem;
    }
`

export const Name = styled.h3`
    font-size:.9rem;
    margin:.5rem 0;
    font-family:'Helvetica-Extra-Light';
    letter-spacing:.35rem;
    text-transform: uppercase;
    color: ${theme.SECONDARY};
    @media (max-width: 650px) {
        font-size:.7rem;
    }
`

export const Title = styled.h1`
    font-size:4.5rem;
    position: relative;
    left: -.5%;
    font-family: 'Helvetica-ExtraBold';
    margin: 1rem 0;
    color:${theme.PRIMARY};
    @media (max-width: 1010px) {
        font-size:3.5rem;
    }
    @media (max-width: 650px) {
        font-size:2.5rem;
    }
`

export const ResumeContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    @media (max-width: 1010px) {
        flex-direction: column;
                    align-items: center;
                    margin-top: 2rem;
                    margin-bottom: 2rem;
    }
`

export const HalfDiv = styled.div`
    width: 50%;
    height: 100%;
    @media (max-width: 1010px) {
        width: 90%;
    }
`

export const Rtitle = styled.h1`
    margin-bottom: 1rem;
    color: ${theme.SECONDARY};
`

export const Rdescription = styled.h3`
    text-transform: uppercase;
    margin-bottom: .5rem;
    color: ${theme.GRAY};
`

export const Rtext = styled.p`
    /* margin-bottom:1rem; */
    color: ${theme.PRIMARY};
    line-height: 25px;
    font-style: italic;
`

export const Rdate = styled.p`
    background: ${theme.LIGHT_GRAY};
    opacity: .5;
    display: inline-block;
    padding: 0.5rem;
    margin-bottom: .5rem;
`

export const EduCollege = styled.p`
    margin-bottom: .5rem;
    span{
        font-family: 'Helvetica-Bold';
        font-style: italic;
    }
`

export const Rlist = styled.ul`
    margin: 1rem;
    list-style: square;
`

export const ListItem = styled.li`
    margin-bottom: .5rem;
`

export const Rbox = styled.div`
    border-left:2px solid ${theme.LIGHT_GRAY};
    position: relative;
    margin: 2rem  .5rem;
    padding: 1rem;
    ::before{
        border-radius: 50%;
        border: 2px solid ${theme.LIGHT_GRAY};
        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        top: -14px;
        left: -8.5px;
    }
`