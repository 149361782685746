import app1Main from '../images/app-1-main.png'
import app1img1 from '../images/app-1-profile.png'
import app1img2 from '../images/app-1-board.png'

import app2Main from '../images/app-2-tweeter-clone.jpg'

import app3Main from '../images/app-3.jpg'
import app3img1 from '../images/app-3-darkmode.png'
import app3img2 from '../images/app-3-library.png'

import app4Main from '../images/app-4.jpg'
import app4img1 from '../images/app-4-compare-page.png'
import app4img2 from '../images/app-4-comparision.png'
import app4img3 from '../images/app-4-profile.png'

import app5Main from '../images/app-5-01.png'
import app5img1 from '../images/app-5-02.png'
import app5img2 from '../images/app-5-03.png'
import app5img4 from '../images/app-5-05.png'
import app5img5 from '../images/app-5-06.png'
import app5img6 from '../images/app-5-07.png'
import app5img7 from '../images/app-5-08.png'
import app5img8 from '../images/app-5-09.png'

import app6Main from '../images/app-6-01.png'
import app6img1 from '../images/app-6-02.png'
import app6img2 from '../images/app-6-03.png'
import app6img3 from '../images/app-6-04.png'
import app6img4 from '../images/app-6-05.png'
import app6img5 from '../images/app-6-06.png'

export const data = [
    {
        year:2022,
        name:'Dx-Dashbii : Dashboard type workflow managements app',
        poster:app1Main,
        otherImages:[app1img1, app1img2],
        stack:[
          "Python",
          "Flask",
          "Google App Engine",
          "JavasScript",
          "HTML5",
          "CSS"
        ],
        paragraphs:[
          "Dx-Dashbii is a user-friendly and efficient task management software, built using a combination of Python, Flask, Google App Engine, JavaScript, HTML5, and CSS. This simple yet powerful software solution empowers teams to seamlessly assign, track, and complete tasks while fostering collaboration within an organized framework.",
          "At its core, Dx-Dashbii offers a straightforward approach to task management. Administrators have the ability to create task boards, assign tasks to users, and oversee the entire process. With a clean and intuitive user interface developed using HTML5 and CSS, administrators can easily manage and maintain these task boards. The Python and Flask backend ensure reliability, while Google App Engine takes care of deployment and scalability.",
          "One of Dx-Dashbii's standout features is its role-based access control. Administrators possess the privilege to create, edit, or delete task boards, ensuring proper organization and control. Meanwhile, dashboard users have the freedom to create, edit, and delete tasks within the boards assigned to them. The user experience is enhanced through JavaScript-driven interactivity, allowing for real-time updates and notifications.",
          "Crucially, Dx-Dashbii introduces a time-sensitive element to task completion. Users are required to fulfill their tasks within specified timeframes, promoting timely task execution and efficient project progress. This feature aids in meeting deadlines and fostering a sense of accountability within the team.",
          "In summary, Dx-Dashbii is a user-centric task management solution, designed to simplify task assignment, tracking, and completion. With its blend of Python, Flask, Google App Engine, JavaScript, HTML5, and CSS, it offers a seamless experience for administrators and users alike. By enabling role-based access, time-bound task completion, and interactive task boards, Dx-Dashbii fosters collaboration and enhances project efficiency in a straightforward and effective manner."
        ]
  
    },
    {
        year:2022,
        name:"Tweeter - Clone : basic working characteristics of social media",
        poster:app2Main,
        otherImages:[],
        stack:[
          "Python",
          "Flask",
          "Google App Engine",
          "JavasScript",
          "HTML5",
          "CSS"
        ],
        paragraphs:[
          "Tweeter-Clone is a user-friendly social networking software project that mirrors the core features of Twitter, offering users the ability to create, edit, and delete posts along with images. Built using a technology stack including Python, Flask, Google App Engine, JavaScript, HTML5, and CSS, this project provides an engaging platform for users to connect, share, and interact.",
          "At its essence, Tweeter-Clone captures the essence of a social network. Users can effortlessly compose posts with accompanying images, fostering creative expression. The project's frontend, fashioned with HTML5 and CSS, delivers a seamless and visually appealing experience, while the robust Python backend, supported by Flask, ensures reliability.",
          "Tweeter-Clone goes beyond basic posting capabilities. It incorporates the social aspect by enabling users to follow and unfollow one another. This dynamic interaction allows users to see posts from accounts they follow. The integration of JavaScript provides real-time updates, enhancing the user experience through instant notifications and content refresh.",
          "Search functionality is a cornerstone feature of Tweeter-Clone. Users can explore posts and profiles with ease, searching for specific tweets or discovering other users. In addition to posts, users can edit or delete their bio and profile pictures, a testament to the project's comprehensive nature.",
          "Powered by Google App Engine, deployment and scalability are seamless, allowing users to focus on engagement rather than infrastructure concerns. The project's technology stack forms a cohesive foundation, ensuring that users enjoy a seamless, interactive, and user-friendly experience.",
          "In summary, Tweeter-Clone offers a simplified version of a social networking platform, encapsulating key features of Twitter. Through Python, Flask, Google App Engine, JavaScript, HTML5, and CSS, it empowers users to connect, share, and engage while showcasing their creativity. With functionalities spanning posts, following, search, and profile customization, Tweeter-Clone provides an enriching social experience for its users."
        ]
    },
    {
        year:2021,
        name:'Music Player : music web app with good UI',
        poster:app3Main,
        otherImages:[app3img1, app3img2],
        stack:[
          "ReactJs",
          "HTML5",
          "Scss",
          "NCS (No Copyright Sounds)",
          "Github"
        ],
        paragraphs:[
          "The Music Player project presents an intuitive and engaging solution for music enthusiasts, allowing them to enjoy a curated selection of pre-available tracks while also giving them the freedom to choose from their own music library. Developed using React JS, HTML5, SCSS, and sourcing music from the renowned No Copyright Sounds (NCS), this project seamlessly blends technology and music for an immersive experience.",
          "The Music Player's functionality extends beyond simple play and pause. Users can skip, fast forward, and rewind tracks, giving them control over their listening experience. The inclusion of light and dark modes adds a personal touch, allowing users to tailor the interface to their preferences and surroundings.",
          "At its core, the Music Player project prioritizes user convenience and enjoyment. It offers a diverse range of tracks sourced from NCS, ensuring copyright-free and high-quality music. Users can easily navigate through this library and select their preferred tunes. The project's frontend, crafted with HTML5 and SCSS, provides an appealing and responsive UI, enhancing the overall user experience.",
          "React JS acts as the backbone of the project, facilitating dynamic updates and interactions. The technology stack is carefully chosen to ensure smooth playback and responsiveness, contributing to a satisfying user journey. Additionally, the integration with NCS guarantees a diverse selection of music that suits various tastes.",
          "In summary, the Music Player project delivers a seamless musical experience. Powered by React JS, HTML5, SCSS, and enriched with NCS's copyright-free tracks, it offers a visually appealing interface, intuitive controls, and a broad spectrum of music choices. By blending technology with music, the project provides users with an immersive and enjoyable way to explore and relish their favorite tunes."
  
        ]
    },
    {
        year:2022,
        name:'Ev - Cars : database of electronic vehicles',
        poster:app4Main,
        otherImages:[app4img1, app4img2, app4img3],
        stack:[
          "NodeJs",
          "ExpressJs",
          "JavaScript",
          "HTML5",
          "CSS",
          "mongoDB"
        ],
        paragraphs:[
          "Ev-Cars is a user-centric software project that empowers individuals to effortlessly manage and explore electric car details. Created using a robust technology stack including Node.js, Express.js, JavaScript, HTML5, CSS, Firebase for authentication, and MongoDB for the database, this project provides an accessible platform for creating, comparing, and evaluating electric vehicle (EV) information.",
          "The heart of Ev-Cars lies in its streamlined approach to EV data management. Users can seamlessly create, edit, and delete electric car details, including accompanying images for visual reference. The HTML5 and CSS-powered interface ensures a visually appealing and intuitive user experience.",
          "User engagement is enhanced through personalized profiles. Each user has their own profile that showcases the electric cars they've added. This feature not only fosters a sense of ownership but also offers a convenient overview of their contributions.",
          "A unique highlight of Ev-Cars is its comparison functionality. Users can compare two or more electric cars side by side, with positive attributes highlighted in green and negative attributes in red. This data-driven approach, supported by JavaScript, enables users to make informed decisions when evaluating different EV models.",
          "Firebase authentication ensures secure access, allowing users to create and compare EV details with confidence. The project's backend, powered by Node.js and Express.js, guarantees smooth interactions and efficient data handling. MongoDB serves as the database, providing reliable storage and retrieval of EV information.",
          "In conclusion, Ev-Cars is a user-friendly solution for managing electric car details. Through its Node.js, Express.js, JavaScript, HTML5, CSS, Firebase, and MongoDB foundation, it offers a comprehensive experience for users interested in electric vehicles. By simplifying data management, fostering comparison, and ensuring secure access, Ev-Cars plays a pivotal role in facilitating exploration and informed decision-making within the EV community."
        ]
    },
    {
      year:2024,
      name:'Gazetteer',
      poster:app5Main,
      link:'https://sahdevodedara50.com/',
      otherImages:[
        app5img1,
        app5img2,
        app5img8,
      ],
      stack:[
          "JavaScript",
          "HTML5",
          "CSS",
          "PHP",
          "Various APIs"
      ],
      paragraphs:[
        "Gazetteer is a user-friendly map app designed to simplify country exploration with its intuitive features, Easily choose any country from a dropdown menu for quick access to its information.",
        "Access essential details about each country, including population count, capital city, and flag, Area, Region, Sub-Region, Neighbours, and image of Coat of Arms ",
        "Gain insights into how people live in the country with percentage breakdowns of rural and urban populations.",
        "Stay informed about current and forecasted weather conditions in your destination to plan activities accordingly. for this user just need to click on weather icon button on map.",
        "View dates of holidays and special events with holiday icon button on map, Conveniently convert currencies using the built-in exchange modal for accurate financial planning while traveling.",
        "Gazetteer relies on various reliable APIs to ensure users have access to accurate and up-to-date information. With Gazetteer, exploring countries is made simple and straightforward, empowering users to discover new destinations with confidence."
      ]
    },
    {
      year:2024,
      name:'Company Directory',
      poster:app6Main,
      link:'http://company-directory.great-site.net/?i=1',
      otherImages:[
        app6img1,
        app6img3,
        app6img5,
      ],
      stack:[
          "JavaScript",
          "HTML5",
          "CSS",
          "PHP",
          "MySQL",
      ],
      paragraphs:[
        "The personnel, departments, and locations database management app simplifies organizational data management. It allows users to effortlessly handle personnel information, department structures, and location logistics.",
        "Key features include easy creation, editing, and deletion of personnel records, along with intuitive department and location management tools. The app ensures data integrity by preventing accidental deletion of departments with associated personnel and locations with associated departments.",
        "With clear data relationships and user-friendly search and filtering options, it promotes efficiency and clarity in data access and decision-making. Overall, it offers a straightforward solution for optimizing organizational data processes and facilitating informed decision-making.",
      ]
    }
  ]