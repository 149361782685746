import styled from "styled-components"
import * as theme from "./variables"
import { motion } from "framer-motion"

export const ContactMeSection = styled(motion.div)`
    width: 100%;
    height: max-content;
    padding:0 5rem;
    display: flex;
    flex-direction:column;
    margin-bottom:3rem;
    @media (max-width: 1300px) {
        margin-bottom:2rem;
        padding:0 2rem;
    }
    @media (max-width: 1010px) {
        margin-bottom:2rem;
        padding:0 1rem;
    }   
`

export const Name = styled.h3`
    font-size:.9rem;
    margin:.5rem 0;
    font-family:'Helvetica-Extra-Light';
    letter-spacing:.35rem;
    text-transform: uppercase;
    color: ${theme.SECONDARY};
    @media (max-width: 650px) {
        font-size:.7rem;
    }
`

export const Title = styled.h1`
    font-size:3.5rem;
    position: relative;
    left: -.5%;
    font-family: 'Helvetica-ExtraBold';
    margin: 1rem 0;
    margin-bottom: 3rem;
    color:${theme.PRIMARY};
    @media (max-width: 1010px) {
        font-size:3.5rem;
    }
    @media (max-width: 650px) {
        font-size:2.5rem;
    }
`

export const ContentBox = styled(motion.div)`
    display: flex;
    width: 95%;
    max-width: 800px;
    margin: auto;
    flex-direction: column;
    @media (max-width: 840px) {
        height: fit-content;
    }
`

export const ContactBox = styled(motion.div)`
    width: 100%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`
export const FormBox = styled(motion.div)`
    width: 100%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Form = styled(motion.form)`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    margin-top: 2rem;
`

export const FormContentBox = styled(motion.div)`
    display: flex;
    width: 100%;
    margin-bottom:2rem;
    @media(max-width: 920px){
        display: flex;
        flex-direction: column;
    }
`

export const Text = styled(motion.p)`
    font-size:1.3rem;
line-height:1.8rem;
font-family: 'Helvetica-Medium';
color: ${theme.LIGHT_GRAY};
`

export const Link = styled(motion.a)`
    font-size: 1.1rem;
    color:${theme.SECONDARY_LIGHT};
`

export const IconBox = styled(motion.div)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 150px;
`

export const IconContainer = styled(motion.div)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const FormContent = styled(motion.div)`
    display: flex;
    flex-direction: column;
    width: 100%;
    &:first-child{
        margin-right: 1rem;
    }
    @media(max-width:920px){
        &:last-child{
            margin-top: 35px;
        }
    }
`

export const Label = styled(motion.div)`
    color: ${theme.GRAY};
    font-size: .9rem;
    font-weight: 100;
`

export const FormInput = styled(motion.input)`
    border:none;
    outline: none;
    border-bottom: 1px solid ${theme.LIGHT_GRAY};
    width: 100%;
    height: fit-content;
    font-size: 1.1rem;
    padding-bottom: 6px;
    margin-top: 8px;
    background: transparent;
    font-family: 'Helvetica-Medium';
    &::placeholder{
        color: ${theme.PRIMARY};
        font-size: 1.1rem;
        font-family: 'Helvetica-Medium';
    }
`

export const FormText = styled(motion.textarea)`
    border:none;
    outline: none;
    border-bottom: 1px solid ${theme.LIGHT_GRAY};
    width: 100%;
    resize: none;
    font-size: 1.1rem;
    display: block;
    padding-bottom: 6px;
    line-height: 25px;
    overflow-y: hidden;
    overflow-x: hidden;
    margin-top: 8px;
    background: transparent;
    font-family: 'Helvetica-Medium';
    &::placeholder{
        color: ${theme.PRIMARY};
        font-size: 1.1rem;
        font-family: 'Helvetica-Medium';
    }
`

export const FormButton = styled(motion.button)`
    color: ${theme.WHITE};
    width: fit-content;
    margin-top: 2rem;
    font-family: 'Helvetica-Medium';
    font-size: medium;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.PRIMARY};
    padding: .5rem 1rem;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    span{
        margin-left: .5rem;
    }
`

export const LineBox = styled(motion.div)`
    height: 10px;
    width: 100%;
    margin: 2rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Line = styled(motion.div)`
    background-color: ${theme.LIGHT_GRAY};
    width: 50px;
    height: 1px;
`

export const LineText = styled(motion.p)`
    padding: 0 .5rem;
    font-size: .7rem;
`