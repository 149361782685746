import react from '../images/react.png'
import python from '../images/python.png'
import javascript from '../images/javascript.png'
import java from '../images/java.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAndroid, faCss3Alt, faHtml5, faJava, faKickstarter, faPython, faReact, faSquareJs } from '@fortawesome/free-brands-svg-icons'
import { faFlask } from '@fortawesome/free-solid-svg-icons'
import {
    LanguageSectionDiv, 
    Name, 
    Title, 
    ContentBox, 
    ThreeDBoxContainer, 
    Scene, 
    Cube, 
    Top, 
    Bottom, 
    Front, 
    LangImg, 
    Back, 
    Left, 
    Right, 
    Languages, 
    LanguageHalf, 
    LanBox, 
    LangText
} from '../styles/LanguageStyles'
import { useInView } from 'react-intersection-observer'
import { useAnimation } from 'framer-motion'
import { useEffect } from 'react'
import {
    leftPartAnimation,
    topPartAnimation,
    rightPartAnimation, 
    frontPartAnimation, 
    backPartAnimation,
    titleAnimation, 
    titleAnimation3, 
    titleAnimation2, 
    titleAnimation4, 
    titleAnimation5, 
    titleAnimation6, 
    titleAnimation7, 
    titleAnimation8, 
    titleAnimation9 } from '../animation/languagepage'

const LanguageSection = () => {
    const view = useInView({threshold:0})
    const controls = useAnimation()

    useEffect(()=>{
        if(view.inView){
            controls.start("show")
        } else {
            controls.start("hidden")
        }
    },[controls, view])

    return ( 
        <LanguageSectionDiv id='language'>
            <Name>Technologies & Frameworks</Name>
            <Title>Good With</Title>
            <ContentBox>
                <ThreeDBoxContainer>
                    <Scene>
                        <div className="floor"></div>
                        <Cube>
                            <Top ref={view.ref} variants={topPartAnimation} animate={controls} initial="hidden" ></Top>
                            <Bottom></Bottom>
                            <Front ref={view.ref} variants={frontPartAnimation} animate={controls} initial="hidden" >
                                <LangImg src={react} alt=""/>
                            </Front>
                            <Back ref={view.ref} variants={backPartAnimation} animate={controls} initial="hidden">
                                <LangImg src={python} alt=""/>
                            </Back>
                            <Left ref={view.ref} variants={leftPartAnimation} animate={controls} initial="hidden" >
                                <LangImg src={javascript} alt=""/>
                            </Left>
                            <Right ref={view.ref} variants={rightPartAnimation} animate={controls} initial="hidden" >
                                <LangImg src={java} alt=""/>
                            </Right>
                        </Cube>
                    </Scene>
                </ThreeDBoxContainer>
                <Languages>
                    <LanguageHalf>
                        <LanBox ref={view.ref} variants={titleAnimation} animate={controls} >
                            <FontAwesomeIcon color='#6c757d'  icon={faPython}  size='3x' />
                            <LangText>Python</LangText>
                        </LanBox>
                        <LanBox ref={view.ref} variants={titleAnimation2} animate={controls} >
                            <FontAwesomeIcon color='#6c757d'  icon={faSquareJs}  size='3x' />
                            <LangText>JavaScript</LangText>
                        </LanBox>
                        <LanBox ref={view.ref} variants={titleAnimation3} animate={controls} >
                            <FontAwesomeIcon color='#6c757d'  icon={faJava}  size='3x' />
                            <LangText>Java</LangText>
                        </LanBox>
                        <LanBox ref={view.ref} variants={titleAnimation4} animate={controls} >
                            <FontAwesomeIcon color='#6c757d'  icon={faReact}  size='3x' />
                            <LangText>React</LangText>
                        </LanBox>
                        <LanBox ref={view.ref} variants={titleAnimation5} animate={controls} >
                            <FontAwesomeIcon color='#6c757d'  icon={faFlask}  size='3x' />
                            <LangText>Flask</LangText>
                        </LanBox>
                    </LanguageHalf>
                    <LanguageHalf>
                        <LanBox ref={view.ref} variants={titleAnimation6} animate={controls} >
                            <FontAwesomeIcon color='#6c757d'  icon={faCss3Alt}  size='3x' />
                            <LangText>Css</LangText>
                        </LanBox>
                        <LanBox ref={view.ref} variants={titleAnimation7} animate={controls} >
                            <FontAwesomeIcon color='#6c757d'  icon={faHtml5}  size='3x' />
                            <LangText>Html</LangText>
                        </LanBox>
                        <LanBox ref={view.ref} variants={titleAnimation8} animate={controls} >
                            <FontAwesomeIcon color='#6c757d'  icon={faAndroid}  size='3x' />
                            <LangText>Android</LangText>
                        </LanBox>
                        <LanBox ref={view.ref} variants={titleAnimation9} animate={controls} >
                            <FontAwesomeIcon color='#6c757d'  icon={faKickstarter}  size='3x' />
                            <LangText>Kotlin</LangText>
                        </LanBox>
                    </LanguageHalf>
                </Languages>
            </ContentBox>
    </LanguageSectionDiv>
     );
} 
export default LanguageSection;