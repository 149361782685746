import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useAnimation } from 'framer-motion'
import { ResumeDiv,Name, Title, ResumeContainer, HalfDiv, Rtitle, Rdescription, Rtext, EduCollege, Rlist, ListItem, Rdate, Rbox } from '../styles/ResumeStyles'
import { data } from '../utils/ResumeData'

const Resume = () => {
    const view = useInView({threshold:0})
    const controls = useAnimation()

    useEffect(()=>{
        if(view.inView){
            controls.start("show")
        } else {
            controls.start("hidden")
        }
    },[controls, view])

    return ( 
        <ResumeDiv id='resume'>
            <Name>About me -</Name>
            <Title>Curriculum Vitae</Title>
            <ResumeContainer>
                <HalfDiv>
                    <Rtitle>Summery</Rtitle>
                    <Rbox>
                    <Rdescription>Sahdev Odedara</Rdescription>
                    <Rtext>A dedicated software developer with 2+ years of experience, with a strong foundation in JavaScript, Java, and Python, well-equipped to tackle diverse software development challenges. Hands- on experience working with popular frameworks such as React, Vue.js, and Next.js. I have a solid understanding of back-end technologies like Node.js and Flask, enabling me to develop robust server- side applications. I am currently looking for entry-level opportunities to contribute my skills and creativity.</Rtext>
                    </Rbox>
                    <Rtitle>Professional Experience</Rtitle>
                    <Rbox>
                    <Rdescription>Full Stack Developer Traineeship</Rdescription>
                    <Rdate>Nov 2022 - May 2024</Rdate>
                    <Rtext>It Career Switch, London, UK</Rtext>
                    <Rlist>
                        <ListItem>Developed responsive web interfaces using HTML, CSS, and JavaScript, and leveraged React.js for creating dynamic single-page applications.</ListItem>
                        <ListItem>Built and maintained server-side applications with Node.js and Express, and designed RESTful APIs for seamless communication between front-end and back-end systems.</ListItem>
                        <ListItem>Worked collaboratively using Git for version control, participated in Agile methodologies including daily stand-ups, sprint planning, and retrospectives to ensure efficient workflow.</ListItem>
                        <ListItem>Implemented unit tests using Jest and Mocha, debugged and resolved issues, and deployed applications on cloud platforms such as AWS and Heroku using continuous integration and deployment (CI/CD) pipelines.</ListItem>
                        <ListItem>Utilized project management tool Jira for task tracking and documentation, participated in code reviews, and attended training sessions to continuously enhance technical skills and stay updated with industry trends.</ListItem>
                    </Rlist>
                    <Rdescription>Web Developer</Rdescription>
                    <Rdate>Jan 2021 - Jun 2021</Rdate>
                    <Rtext>Achyut Labs Pvt Ltd</Rtext>
                    <Rtext>It is tech solution company, provides software solutions to world wide</Rtext>
                    <Rlist>
                        <ListItem>Completed a 6-month internship focused on web development technologies.</ListItem>
                        <ListItem>Developed responsive web pages using HTML5, CSS (Bootstrap and Vuetify), and JavaScript (VueJS).</ListItem>
                        <ListItem>Gained expertise in CSS frameworks and VueJS, React JS.</ListItem>
                        <ListItem>Received certification from Achyut Labs Pvt Ltd for successful completion of the internship</ListItem>
                    </Rlist>
                    
                    </Rbox>
                </HalfDiv>
                <HalfDiv>
                    
                    <Rtitle>Education and Training</Rtitle>
                    <Rbox>
                    <Rdescription>Master of Science in Computer Science</Rdescription>
                    <Rdate>Jan 2022 – June 2023</Rdate>
                    <EduCollege className='college'>Griffith College, Dublin - <span>71% first class honors</span></EduCollege>
                    <Rdescription>Bachelor of Engineering in Computer Engineering</Rdescription>
                    <Rdate>Aug 2017 – June 2021</Rdate>
                    <EduCollege className='college'>Gujarat Technological University - <span>8.2/10 CGPA</span> </EduCollege>
                    <Rdescription>Online Courses</Rdescription>
                    <Rlist>
                        <ListItem>Full Stack Engineering - Codecademy</ListItem>
                        <ListItem>The Web Developer Bootcamp 2023 by Colt Steele – Udemy</ListItem>
                        <ListItem>The Complete Android 12 & Kotlin Development Masterclass – Udemy</ListItem>
                        <ListItem>Modern React with Redux by Stephen Grider – Udemy</ListItem>
                        <ListItem>NodeJS - The Complete Guide (MVC, REST APIs, GraphQL, Deno) by Academind by MaximilianSchwarzmüller – Udemy</ListItem>
                    </Rlist>
                    </Rbox>
                    <Rtitle>Projects</Rtitle>
                    <Rbox>
                    {data.map(item=>(
                        <div>
                            <Rdescription>{item.name}</Rdescription>
                            <Rdate>{item.date}</Rdate>
                            <Rlist>
                                {item.texts.map(text=>(
                                    <ListItem>{text}</ListItem>
                                ))}
                            </Rlist>
                        </div> 
                    ))}
                    </Rbox>
                </HalfDiv>
            </ResumeContainer>
        </ResumeDiv>
  )
}

export default Resume