import React from 'react'
import { MainContainer, ContentSection, ImageSection, FirstHalf, SecondHalf, Name, Title, Image, Box, Paragraph, List } from '../styles/SingleProject'

import { pageTransition, titleAnimation,  fade, paragraphAnimation } from '../animation/projectpage'
import {motion} from 'framer-motion'

const SingleProject = ({project}) => {
    console.log(project)
  return (
    <MainContainer variants={pageTransition} animate="show" initial="hidden" exit="exit">
        <ContentSection>
            <FirstHalf variants={fade} animate="show" initial="hidden">
                <Box>
                    <Title variants={titleAnimation} >Project Title</Title>
                    <Name variants={titleAnimation} >{project.name}</Name>
                </Box>
                <Box>
                    <Title variants={titleAnimation}>Tech Stack</Title>
                    <motion.ul variants={titleAnimation}>
                        {
                            project.stack.map((item, index)=>(
                                <List key={index} >{item}</List>
                            ))
                        }
                    </motion.ul>
                </Box>
                <Box>
                    <Title variants={titleAnimation} >Year</Title>
                    <motion.h3 variants={titleAnimation}>{project.year}</motion.h3>
                </Box>
            </FirstHalf>
            <SecondHalf variants={fade} animate="show" initial="hidden">
                {
                    project.paragraphs.map((item, index)=>(
                        <Paragraph variants={paragraphAnimation} key={index} >{item}</Paragraph>
                    ))
                }
            </SecondHalf>
        </ContentSection>
        <ImageSection>
            <Image src={project.poster} alt="" />
                {
                    project.otherImages.map((item, index)=>(
                        <Image src={item} alt="" key={index}/>
                    ))
                }
        </ImageSection>
    </MainContainer>
  )
}

export default SingleProject