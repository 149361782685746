import React, { useState } from 'react'
import logo from '../images/main-logo.png'
import { HashLink } from 'react-router-hash-link';

import { Container, Logo, Navbar, Lines, Links, LineBox,LinkTitle,MenuText,Footer} from '../styles/Header'
import { slidein, slideLink1, slideLink2, slideLink3 } from '../animation/headeranimation'
import { AnimatePresence } from 'framer-motion'

const Header = () => {
const [open, setOpen] = useState(false)
  return (
    <>
      <Container>
      <Navbar className='menubar'>
        <HashLink to="/#about">
          <Logo menuopen={open} src={logo} />
        </HashLink>
        <LineBox menuopen={open} onClick={()=>setOpen(!open)} >
          <Lines menuopen={open} />
        </LineBox>
      </Navbar>
      <AnimatePresence>
      {
        open ? (<Links menuopen={open} variants={slidein} animate="show" initial="hidden" exit="exit">
        <MenuText>MENU</MenuText>
        <HashLink to="/#about">
          <LinkTitle variants={slideLink1} animate="show" initial="hidden" exit="exit" onClick={()=>setOpen(false)} >Home</LinkTitle>
        </HashLink>
        <HashLink to="/#project">
          <LinkTitle variants={slideLink2} animate="show" initial="hidden" exit="exit" onClick={()=>setOpen(false)} >Projects</LinkTitle>
        </HashLink>
        <HashLink to="/#language">
          <LinkTitle variants={slideLink3} animate="show" initial="hidden" exit="exit" onClick={()=>setOpen(false)} >Languages</LinkTitle>
        </HashLink>
        <HashLink to="/#resume">
          <LinkTitle variants={slideLink3} animate="show" initial="hidden" exit="exit" onClick={()=>setOpen(false)} >About</LinkTitle>
        </HashLink>
        <HashLink to="/#contactme">
          <LinkTitle variants={slideLink3} animate="show" initial="hidden" exit="exit" onClick={()=>setOpen(false)} >Contact</LinkTitle>
        </HashLink>
        <Footer>&copy;{new Date().getFullYear()}  Sahdev V. Odedara - Dublin,Ireland</Footer>
      </Links>):""
      }
      </AnimatePresence>
    </Container>
    </>
  )
}

export default Header