import styled from 'styled-components'
import * as theme from './variables'


export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding:3rem 5rem;
    @media (max-width: 1000px) {
        padding:3rem 1rem;
    }
    @media (max-width: 720px) {
        padding:1rem 1rem;
        height: fit-content;
    }
    width: 100%;
    position: relative;
    height: 50vh;
    align-items: center;
    justify-content: space-around;
`

export const FooterLine = styled.p`
position: absolute;
width: 80%;
height: 2px;
background-color:${theme.LIGHT_GRAY};
opacity: .2;
top: 0;

`
export const Container = styled.div`
    /* background-color: green; */
    display: flex;
    padding:3rem 5rem;
    width: 100%;
    justify-content: space-around;
    @media (max-width: 1000px) {
        padding:3rem 1rem;
    }
    @media (max-width: 720px) {
        padding:2rem 1rem;
        flex-direction: column-reverse;
        height: fit-content;
    }
`

export const Content = styled.div`
    /* background-color: red; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @media (max-width: 720px) {
        margin-bottom: 3rem;
    }
`

export const Text = styled.p`
    color: ${theme.SECONDARY_LIGHT};
    font-weight: 400;
    font-size: 1rem;
`

export const LinkText = styled.p`
    color: ${theme.SECONDARY};
    font-weight: 400;
    font-size: .8rem;
    @media (max-width: 720px) {
        :first-child{
            margin-top: 1rem;
        }
    }
`

export const Link = styled.a`
    color: ${theme.PRIMARY};
    font-weight: 600;
`
export const Title = styled.h2`
    color: ${theme.PRIMARY};
    font-family: 'Helvetica-ExtraBold';
`

export const List = styled.li`
    list-style: none;
    font-weight: 400;
    color: ${theme.SECONDARY};
`

export const ListLink = styled.a`
    color: ${theme.SECONDARY};
    :hover{
        text-decoration: underline;
    }
`

export const FooterLogo = styled.img`
    @media (max-width: 720px) {
        display: none;
    }
`

export const CopyrightText = styled.p`
    position: absolute;
    bottom: 1rem;
    color: ${theme.SECONDARY_LIGHT};
    transform: scale(.8);
`
