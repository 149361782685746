export const data = [
    {
        name: "Gazetteer",
        date: "March 2024 – April 2024",
        texts:[
            "Users can easily select any country from a dropdown menu for exploration.",
            "Automatically selects the user's live location country by default, if user agrees for location access.",
            "Various icon buttons like, information, wikipedia, currency-exchange, weather forecast, popuation distribution, public holidays all of them will show selected country data",
            "Highlights the ten most significant cities within the selected country directly on the map",
            "smooth animation when user select one to another country"
        ]
    },
    {
        name: "Company Directory",
        date: "April 2024 – May 2024",
        texts:[
            "Users can create, update and delete records of personnel, departments and locations",
            "Better data management, departments are linked to personnel, and locations are linked to departments which means users cant delete them unless and until there's no record found in them",
            "Users can efficiently filter personnel data based on department or location using the filter modal",
        ]
    }
]