import React, { useRef, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer'
import emailjs from '@emailjs/browser';
import {useAnimation} from 'framer-motion'
import { fade } from '../animation/homepage';
import { ContactMeSection, ContentBox, FormBox, Name, Title, ContactBox, Link, Text, IconBox, Form, FormContentBox, FormContent, FormInput, Label, FormText, FormButton, LineBox, Line, LineText, IconContainer } from '../styles/ContactMeStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { RiMailSendLine } from "react-icons/ri";


export const ContactMe = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    from_name:"",
    reply_to:"",
    message:""
  })

  function handleOnchange(event) {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    console.log(formData)
  }

  const notify = (name) => toast(`${name} your messsage has been successfully sent!`);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('service_teii3tb', 'template_uk5ktfn', form.current, {
        publicKey: 'kpIcJUVq_nXnNXFNt',
      })
      .then(
        () => {
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
      notify(formData.from_name)
      setFormData({
        from_name:"",
        reply_to:"",
        message:""
      })
  };

    const view = useInView({threshold:0})
    const controls = useAnimation()

    useEffect(()=>{
        if(view.inView){
            controls.start("show")
        } else {
            controls.start("hidden")
        }
    },[controls, view])

    const textAreaRef = useRef(null);
    
    useEffect(() => {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
    }, [formData.message])

  return (
    <ContactMeSection id="contactme">
      <Name>Contact</Name>
      <Title>Let's get in touch</Title>
      <ContentBox>
        <ContactBox ref={view.ref} variants={fade} animate={controls}>
          <Text>Got a project? Drop me a line if you want to work together on something exciting. Big or small. Mobile or web.</Text>
        </ContactBox>
        <FormBox ref={view.ref} variants={fade} animate={controls}>
          <Form ref={form} onSubmit={sendEmail}>
            <FormContentBox>
              <FormContent>
                <Label>Your Name</Label>
                <FormInput placeholder="What's your name?" type="text" value={formData.from_name} name="from_name" autoComplete="off" required onChange={(e)=>handleOnchange(e)}/>
              </FormContent>
              <FormContent>
                <Label>Your Email</Label>
                <FormInput placeholder="What's your email?" type="email" value={formData.reply_to} name="reply_to" autoComplete="off" required onChange={handleOnchange}/>
              </FormContent>
            </FormContentBox>
            <Label>Message</Label>
            <FormText placeholder="What's your message" name="message" value={formData.message} onChange={handleOnchange} rows="1" ref={textAreaRef} autoComplete="off" required/>
            <FormButton type='submit' whileHover={{opacity:.9}} whileTap={{scale:.9}}>
              <RiMailSendLine />  <span>Send Message</span>
            </FormButton>
          </Form>
        </FormBox>
        <LineBox>
          <Line />
          <LineText>Or</LineText>
          <Line />
        </LineBox>
        <IconContainer>
          <IconBox>
              <Link href='https://www.instagram.com/sahdev_odedara/' target='_blank' ><FontAwesomeIcon icon={faInstagram} size='xl' color='#343a40' /></Link>
              <Link href='https://www.linkedin.com/in/sahdevodedara/' target='_blank' ><FontAwesomeIcon icon={faLinkedin} size='xl' color='#343a40'/></Link>
              <Link href='https://www.facebook.com/odedra.sahdev' target='_blank' ><FontAwesomeIcon icon={faFacebook} size='xl' color='#343a40'/></Link>
            </IconBox>
        </IconContainer>
      </ContentBox>
      <ToastContainer
      />
    </ContactMeSection>
  );
};