import styled from 'styled-components'
import * as theme from './variables'
import { motion } from 'framer-motion'

export const LanguageSectionDiv = styled(motion.div)`
    width: 100%;
    height: 100vh;
    padding:0 5rem;
    margin-top: 4rem;
    display: flex;
    margin-bottom: 3rem;
    flex-direction:column;
    @media (max-width: 1300px) {
        margin-bottom:2rem;
        height: fit-content;
        padding:0 2rem;
    }
    @media (max-width: 1010px) {
        margin-bottom:2rem;
        padding:0 1rem;
    }
`

export const Name = styled.h3`
    font-size:.9rem;
    margin:.5rem 0;
    font-family:'Helvetica-Extra-Light';
    letter-spacing:.35rem;
    text-transform: uppercase;
    color: ${theme.SECONDARY};
    @media (max-width: 650px) {
        font-size:.7rem;
    }
`

export const Title = styled.h1`
    font-size:4.5rem;
    position: relative;
    left: -.5%;
    font-family: 'Helvetica-ExtraBold';
    margin: 1rem 0;
    color:${theme.PRIMARY};
    @media (max-width: 1010px) {
        font-size:3.5rem;
    }
    @media (max-width: 650px) {
        font-size:2.5rem;
    }
`

export const ContentBox = styled(motion.div)`
    display: flex;
    width: 100%;
    height: 100%;
    @media (max-width: 840px) {
        flex-direction: column;
        height: fit-content;
    }
`

export const Languages = styled(motion.div)`
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 2rem;
    @media (max-width: 1300px) {
        height: fit-content;
        margin: auto;
    }
    @media (max-width: 760px) {
        width: 100%;
    }
`

export const LanguageHalf = styled(motion.div)`
    width: 45%;
    @media (max-width: 1300px) {
        width: 100%;
    }
`

export const LanBox = styled(motion.div)`
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    padding:0 1rem;
    align-items:center;
    justify-content:space-around;
`

export const LangText = styled.h3`
    margin-left:1rem;
    color: ${theme.SECONDARY};
    font-family: 'Helvetica-Extra-Light';
`

export const ThreeDBoxContainer = styled(motion.div)`
     width: 50%;
    height: 100%;
    background-color: ${theme.WHITE};
    display: flex;
    justify-content: center;
    align-items: center;
    perspective: 10em;
    overflow: hidden;
    font-size: 75px;
    perspective-origin: 50% calc(50% - 1em);
    @media (max-width: 1300px) {
        width: 100%;
        height: 500px;
        perspective: 13em;
        perspective-origin: 50% calc(50% - 3em);
    }
`

export const Scene = styled(motion.div)`
    position: relative;
    transform-style: preserve-3d;
`

export const Cube = styled(motion.div)`
position: absolute;
    transform-style: preserve-3d;
    top: -2.5em;
    left: -2em;
    width: 4em;
    height: 4em;
    
        width: 3em;
        height: 3em;
        top: -2em;
        left: -1.5em;
    
    /* transform: scale(2); */
    animation: cubeRotate 45s infinite linear;
    @keyframes cubeRotate{
    to{
        transform: rotateY(360deg);
    }
}
`

export const Top = styled(motion.div)`
    position: absolute;
    width: 100.1%;
    height: 100.1%;
    border:2px solid ${theme.SECONDARY};
    background: ${theme.SECONDARY_LIGHT};
    transform: translate(0%, -50%) rotateX(90deg);
`
export const Bottom = styled(motion.div)`
    position: absolute;
    width: 100.1%;
    height: 100.1%;
    border: 2px solid ${theme.SECONDARY};
    background: ${theme.SECONDARY_LIGHT};
    transform: translate(0%, 50%) rotateX(90deg);
    box-shadow:10px 0px 100px 10px ${theme.PRIMARY};
    @media (max-width: 600px) {
        width: 100%;
        height: 100%;
        box-shadow:0px 0px 0px 0px ${theme.PRIMARY};
    }
`
export const Left = styled(motion.div)`
    position: absolute;
    width: 101%;

    height: 100.1%;
    border: 2px solid ${theme.SECONDARY};
    background: ${theme.SECONDARY_LIGHT};
    /* box-shadow: 0 0 0.5em -.1em rgba(225, 225, 225, 0.667) inset; */
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotateY(270deg) translateZ(2em);

        left: .5em;
        width: 100%;
        height: 100%;
    
`
export const Right = styled(motion.div)`
    position: absolute;
    width: 101%;
    height: 100.1%;
    border: 2px solid ${theme.SECONDARY};
    background: ${theme.SECONDARY_LIGHT};
    /* box-shadow: 0 0 0.5em -.1em rgba(225, 225, 225, 0.667) inset; */
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotateY(90deg) translateZ(2em);
        right: .5em;
        width: 100%;
        height: 100%;
`
export const Front = styled(motion.div)`
    position: absolute;
    width: 101%;
    height: 100.1%;
    border: 2px solid ${theme.SECONDARY};
    background: ${theme.SECONDARY_LIGHT};
    /* box-shadow: 0 0 0.5em -.1em rgba(225, 225, 225, 0.667) inset; */
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateZ(2em);

        transform: translateZ(1.5em);
        width: 100%;
        height: 100%;
    
`
export const Back = styled(motion.div)`
    position: absolute;
    width: 101%;
    height: 100.1%;
    border: 2px solid ${theme.SECONDARY};
    background: ${theme.SECONDARY_LIGHT};
    /* box-shadow: 0 0 0.5em -.1em rgba(225, 225, 225, 0.667) inset; */
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotateY(180deg) translateZ(2em);

        transform: rotateY(180deg) translateZ(1.5em);
        width: 100%;
        height: 100%;

`

export const LangImg = styled.img`
    padding: 1rem;
    margin-bottom: 1rem;
    width: 80%;
    height: 80%;
`