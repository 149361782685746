import styled from 'styled-components'
import twill from '../images/twill.png'
import * as theme from './variables'
import { motion } from 'framer-motion'

export const AboutSection = styled(motion.div)`
    width: 100%;
    height: 100vh;
    padding:0 5rem;
    margin-bottom: 10rem;
    display: flex;
    overflow: hidden;
    position: relative;
    background: ${theme.WHITE};
    @media (max-width: 1200px) {
        padding: 1rem 1rem;  
        overflow: hidden;
    }
    @media (max-width: 1010px) {
        overflow-x:hidden !important;
        overflow-y:hidden !important;
        max-height: fit-content;
        margin-bottom: 3rem;
        /* height: 200vh; */
    }
    @media (max-width: 650px) {
        margin-bottom: 3rem;
        padding: 0em;
    }
`

export const About = styled.div`
    background:${theme.WHITE};
    width: 90%;
    height: 100%;
    display:flex;
    align-items:center;
    justify-content:center;
    @media (max-width: 1200px) {
        padding: 1rem 1rem;
    }
    @media (max-width: 1010px) {
        margin: auto;
        position: relative;
        flex-direction: column-reverse;
    }
    @media (max-width: 650px) {
        padding: 0em;
    }
`

export const Description = styled(motion.div)`
width: 60%;
height: 100%;
display: flex;
flex-direction:column;
justify-content:center;
@media (max-width: 1010px) {
        width: 100%;
        top: 10;
        z-index: 150!important;
        /* background: linear-gradient(to top, rgba(255 255,255,1), rgba(255 255,255,0.7)); */
    }
    @media (max-width: 530px) {
        height: 100%;
        margin: auto;
        top: 0;
    }
    /* @media (max-width: 650px) {
        height: 80%;
    } */
`

export const Name = styled(motion.h3)`
    font-size:.9rem;
    margin:.5rem 0;
    font-family:'Helvetica-Extra-Light';
    letter-spacing:.35rem;
    text-transform: uppercase;
    color: ${theme.SECONDARY};
`

export const Title = styled(motion.h2)`
font-size:4.5rem;
position: relative;
left: -.5%;
font-family: 'Helvetica-ExtraBold';
margin: 1rem 0;
color:${theme.PRIMARY};
@media (max-width: 1010px) {
    font-size:3.5rem;
    }
`

export const Paragraph = styled(motion.p)`
font-size:1.3rem;
line-height:1.8rem;
font-family: 'Helvetica-Medium';
color: ${theme.LIGHT_GRAY};
padding-right:2rem;
margin-bottom:1rem;
`

export const LinkButton = styled(motion.p)`
    display: inline-block;
    color: ${theme.PRIMARY};
    font-family: 'Helvetica-ExtraBold';
    border-bottom: 2px solid transparent;
    transition: all 0.15s;
    transition-timing-function: cubic-bezier(1, 0.1, 1.5, 0.1);
    cursor: pointer;
    &:hover{
        background: ${theme.WHITE};
        color: ${theme.PRIMARY};
        border-bottom: 2px solid ${theme.PRIMARY};
    }
`

export const Image = styled(motion.div)`
    position: relative;
    width: 40%;
    height: 100%;
    z-index:100;
    user-select: none;
    @media (max-width: 1010px) {
        width: 100%;
        /* mask-image: linear-gradient(45deg, rgba(0,0,0,1), rgba(0,0,0,0)); */
    }
    @media (max-width: 650px) {
        width: 150%;
    }
    @media (max-width: 500px) {
        width: 180%;
        right: 0;
    }
`

export const PatternBackground = styled(motion.div)`
    position: absolute;
    user-select: none;
    top: 30%;
    height: 45%;
    width: 100%;
    z-index:0;
    background-color: white;
    background-image: url(${twill});
    background-repeat: repeat;
    @media (max-width: 1010px) {
        display: none;
    }
`

export const HeroPoster = styled(motion.img)`
    @media (min-width: 1201px) {
        position: absolute;
        user-select: none;
        -webkit-user-drag: none;
        bottom: 0%;
        right: -15%;
        z-index:100;
        height: 90%;
    }
    @media (min-width: 1010.1px) and (max-width:1200px) {
        position: absolute;
        user-select: none;
        -webkit-user-drag: none;
        bottom: 0%;
        z-index:100;
        right: 0%;
        width: 160%;
        height: 90%;
    }
    @media (max-width: 1010px) {
        width: 100%;
        margin-top: 5rem;
        mask-image: linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0));
        position: absolute;
        user-select: none;
        -webkit-user-drag: none;
        z-index:100;
        right: 0%;
    }
    @media (max-width: 650px) {
        margin-top: 6rem;
    }
`
export const SocialIconsContainer = styled(motion.div)`
    width: 10%;
    height: 100%;
    display: flex;
    align-items:center;
    justify-content:center;
    @media (max-width: 1010px) {
        display: none;
    }
`

export const SocialIcons = styled(motion.div)`
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-around;
    `
    export const Line = styled.div`
    height: 2rem;
    width: 2px;
    background: ${theme.PRIMARY};
`