export const topPartAnimation = {
    hidden:{opacity:0, y:-300, rotateX:90},
    show:{opacity:1,y:-112, transition:{duration:.5}}
}

export const leftPartAnimation = {
    hidden:{opacity:0, x:-300, rotateY:-90},
    show:{opacity:1,x:-150, transition:{duration:1, delay:.5}}
}

export const rightPartAnimation = {
    hidden:{opacity:0, x:300, rotateY:-90},
    show:{opacity:1,x:150, transition:{duration:1, delay:.5}}
}

export const frontPartAnimation = {
    hidden:{opacity:0, z:300},
    show:{opacity:1,z:112,skew:0, transition:{duration:1, delay:.5}}
}

export const backPartAnimation = {
    hidden:{opacity:0, z:-300},
    show:{opacity:1,z:-112, transition:{duration:1, delay:.5}}
}

export const titleAnimation = {
    hidden:{x:100, opacity:0},
    show:{x:0, opacity:1, transition:{duration:.45,type: "ease"}}
}
export const titleAnimation1 = {
    hidden:{x:100, opacity:0},
    show:{x:0, opacity:1, transition:{duration:.45,type: "ease", delay:.15}}
}
export const titleAnimation2 = {
    hidden:{x:100, opacity:0},
    show:{x:0, opacity:1, transition:{duration:.45,type: "ease",delay:.25}}
}
export const titleAnimation3 = {
    hidden:{x:100, opacity:0},
    show:{x:0, opacity:1, transition:{duration:.45,type: "ease",delay:.35}}
}
export const titleAnimation4 = {
    hidden:{x:100, opacity:0},
    show:{x:0, opacity:1, transition:{duration:.45,type: "ease",delay:.45}}
}
export const titleAnimation5 = {
    hidden:{x:100, opacity:0},
    show:{x:0, opacity:1, transition:{duration:.45,type: "ease",delay:.55}}
}
export const titleAnimation6 = {
    hidden:{x:100, opacity:0},
    show:{x:0, opacity:1, transition:{duration:.45,type: "ease",delay:.65}}
}
export const titleAnimation7 = {
    hidden:{x:100, opacity:0},
    show:{x:0, opacity:1, transition:{duration:.45,type: "ease", delay:.75}}
}
export const titleAnimation8= {
    hidden:{x:100, opacity:0},
    show:{x:0, opacity:1, transition:{duration:.45,type: "ease",delay:.85}}
}
export const titleAnimation9= {
    hidden:{x:100, opacity:0},
    show:{x:0, opacity:1, transition:{duration:.45,type: "ease",delay:.95}}
}