export const slide = {
    hidden:{x:"-100%", skew:"15deg"},
    show:{x:"220%",skew:"10deg", transition:{duration:2.5}},
}

export const slideContainer = {
    // hidden:{opacity:1},
    show:{transition:{delay:1}}
}

export const fade = {
    hidden:{
        scale:1.5, opacity:0
    },
    show:{scale:1, opacity:1,transition:{duration:.35,type: "ease", staggerChildren:0.55, delay:.5}}
}

export const titleAnimation = {
    hidden:{x:-500, opacity:0},
    show:{x:0, opacity:1, transition:{duration:1.5,type: "spring"}}
}

export const paragraphAnimation = {
    hidden:{x: 600, opacity:0,},
    show:{x: 0, opacity:1, transition:{duration:1.5,type: "spring"}}
}

export const pageTransition = {
    hidden:{ y: -20, opacity: 0 },
    show:{ y: 0, opacity: 1,transition:{duration: 0.7,ease: [0.6, -0.05, 0.01, 0.99], delay:.5}},
    exit:{y: -20, opacity: 0,transition:{duration: 0.7,ease: [0.6, -0.05, 0.01, 0.99]}}
}

export const firstContainer = {
    hidden:{
        x:100,
        opacity:0
    },
    show:{
        x:0,
        opacity:1,
        transition:{
            duration:1
        }
    }
}

export const secondContainer = {
    hidden:{
        x:-100,
        opacity:0
    },
    show:{
        x:0,
        opacity:1,
        transition:{
            duration:1,
        }
    }
}

export const ImageSlideAnimation = {
    hidden:{
        x:500,
        opacity:0
    },
    show:{
        x:0,
        opacity:1,
        transition:{
            duration:1,
            ease: [0.6, -0.05, 0.01, 0.99]
        }
    }
}