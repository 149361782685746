import { createGlobalStyle } from "styled-components";
import styled from 'styled-components'
import * as theme from './variables'
import { motion } from 'framer-motion'

export const GlobalStyles = createGlobalStyle`
    *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-user-drag: none;
    ::selection{
        color: ${theme.WHITE};
        background-color: ${theme.GRAY};
    }
}
html{
    position: relative;
    overflow-x: hidden !important;
}
body{
    width: 100%;
    overflow-x: hidden !important;
    position: relative;
    /* overflow-x:hidden !important; */
    background-color: #f8f9fa;
    font-family: 'Helvetica-Regular';
}
`
export const Frame1 = styled(motion.div)`

position: fixed;
width: 100%;
height: 100%;
z-index: 2000;
top: 0;
left: 0%;
background-color: ${theme.GRAY};
transform-origin:bottom;
`
export const Frame2 = styled(motion.div)`

position: fixed;
width: 200%;
height: 100%;
z-index: 2000;
top: 0;
background-color: ${theme.PRIMARY};
transform-origin:bottom;
`

export const TopScrollButton = styled(motion.button)`
position: fixed;
display: ${(props)=> props.visible ? 'flex' : 'none'};
background-color: ${theme.PRIMARY};
border: none;
color: ${theme.WHITE};
cursor: pointer;
border-radius: 50px;
padding: 1rem;
bottom: 1rem;
right: 2rem;
z-index: 500;
`