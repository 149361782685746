import app5Main from '../images/app-5-main.jpg'
import app6Main from '../images/app-6-main.jpg'

import { useState } from 'react'

import {Modal} from 'react-responsive-modal'

import {data as projectData} from '../utils/ProjectData'

import 'react-responsive-modal/styles.css';

import { motion, AnimatePresence, AnimateSharedLayout, LayoutGroup } from 'framer-motion'

import { GoLinkExternal } from "react-icons/go";

import { 
    ProjectSectionDiv, 
    ProjectsCards, 
    Name, 
    Title, 
    Card, 
    TextBox, 
    CardYear,
    CardTitle, 
    Layer, 
    Poster,
    FirstContainer,
    SecondContainer,
    ThirdContainer
} from '../styles/ProjectsStyles'

import { MainContainer, ContentSection, ImageSection, FirstHalf, SecondHalf, Image, Box, Paragraph, List } from '../styles/SingleProject'

import {firstContainer } from '../animation/projectpage'
import { useEffect} from 'react'
import { useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer'

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex:'1000 !important',
      scrollEvent:'none',
      transition:'0.5'
    },
  };

const ProjectSection = () => {
    const view = useInView({
        /* Optional options */
        threshold: 0.1,
      });

    const controls = useAnimation()
    useEffect(()=>{
        if(view.inView){
            controls.start("show")
        } else {
            controls.start("hidden")
        }
    },[view, controls])

    const data1 = [
        {
            year:2024,
            name:"Gazetteer - All country's basic knowledge",
            poster:app5Main,
            link:"/projects/5"
        },
        {
            year:2024,
            name:'Company Directory : database management application',
            poster:app6Main,
            link:"/projects/6"
        }
    ]

    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalIndex, setModalIndex] = useState(null);

  function openModal(id) {
    setIsOpen(true);
    setModalIndex(id)
    if (typeof window != 'undefined' && window.document) {
        document.documentElement.style.overflow = 'hidden';
        document.querySelector('.menubar').style.display = 'none'
    }
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
    document.documentElement.style.overflow = '';
    document.querySelector('.menubar').style.display = 'block'
  }

  function ModalHelper({id}){
    return(
        <Modal
            open={modalIsOpen}
            onClose={closeModal}
            classNames={{
                overlay: 'customOverlay',
                modal: 'customModal',
              }}
        >
            <h1>{projectData[id].name}</h1>
            <div className='modal-card'>
            <div className='card-stack-box' >
              <div className='card-stack' >
              <h3>Tech - Stack</h3>
                <ul>
                    {
                        projectData[id].stack.map((item, index)=>(
                            <List key={index} >{item}</List>
                        ))
                    }
                </ul>
              </div>
              <div className='card-links'>
                <a href={projectData[id].link} className='demo-link' target='_blank'><h3>Live Demo</h3><GoLinkExternal /></a>
              </div>
            </div>
            <div>{
            projectData[id].paragraphs.map((para, index)=>(
                <p key={index}>{para}</p>
            ))
            }</div>
            <img src={projectData[id].poster} alt={projectData[id].name} className='modal-img' />
            {
                        projectData[id].otherImages.map((item, index)=>(
                          <img src={item} alt="modal-img" key={index} className='modal-img' />
                        ))
            }
            </div>
        </Modal>
    )
  }

    return ( 
        <ProjectSectionDiv id='project'>
           {/* <LayoutGroup animate={{ x: 100 }} transition={{ ease: "easeOut", duration: 2 }}> */}
           <AnimatePresence>{modalIndex&&<ModalHelper  id={modalIndex} />}</AnimatePresence>
            <Name>Selected Projects</Name>
            <Title>Case Studies</Title>
            <ProjectsCards>
                <FirstContainer ref={view.ref} variants={firstContainer} animate={controls} initial="hidden" >
                {
                    data1.map((app,index)=>(
                            <Card  onClick={()=>openModal(index+4)} >
                                <TextBox>
                                    <CardYear>{app.year}</CardYear>
                                    <CardTitle>{app.name}</CardTitle>
                                </TextBox>
                                <Layer></Layer>
                                <Poster src={app.poster} alt="poster of project" width={450} height={650} loading='lazy'/>
                            </Card>
                    ))
                }
                </FirstContainer>
            </ProjectsCards>
           {/* </LayoutGroup> */}
        </ProjectSectionDiv>
     );
}

 
export default ProjectSection;