import React, {useEffect} from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { ProjectContainer } from '../styles/SingleProject'

import { data } from '../utils/ProjectData'

import SingleProject from '../components/SingleProject'

import { motion } from 'framer-motion'
import transition from '../animation/transition'


const Project = () => {

  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const {projectId} = useParams()
  return (
    <motion.div>
      <ProjectContainer>
        {data.map((item, index)=>{
          if (parseInt(projectId) === parseInt(index+1)) {
            console.log(index)
            return <SingleProject project={item} key={index}/>
          } else {
            return ""
          }
        })}
      </ProjectContainer>
    </motion.div>
  )
}

export default transition(Project)