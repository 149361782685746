import styled from 'styled-components'
import * as theme from './variables'
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
    position: fixed;
    top: 0;
    display: block;
    width: 100%;
    z-index: 1001;
    @media (max-width: 650px) {
        width: 100%;
    }
`;

export const Navbar = styled(motion.div)`
    top: 0;
    position: absolute;
    width: 100%;
    z-index: 1002;
`;

export const Logo = styled.img`
    width: 250px;
    position: absolute;
    top: 1rem;
    left: 2rem;
    filter: ${(props)=> props.menuopen ? 'invert(1)' : 'invert(0)'};
    @media (max-width: 650px) {
        width: 200px;
    }
`
export const Lines = styled(motion.div)`
cursor: pointer;
transform: scale(1);
    width: 2rem;
    height: .2rem;
    position: absolute;
    bottom: 10px;
    right: 10px;
    transition: .15s;
    display: inline-block;
    /* background-color: ${theme.WHITE}; */
    background: ${(props)=> props.menuopen ? 'rgba(0, 0, 0, 0)' : theme.PRIMARY};
    ::before{
        content: '';
transform: ${(props)=> props.menuopen ? 'scale(1) rotate(45deg)' : 'scale(1)'};

        position: absolute;
        top: ${(props)=> props.menuopen ? '0' : '10px'};
        width: 2rem;
        height: .2rem;
        /* background-color: ${theme.SECONDARY}; */
        background:${(props)=> props.menuopen ? theme.WHITE : theme.PRIMARY};
        transition: ${(props)=> props.menuopen ? '.45s' : ' .1s'};
    }
    ::after{
        content: '';
        position: absolute;
        transform: ${(props)=> props.menuopen ? 'scale(1) rotate(-45deg)' : 'scale(1)'};
        top: ${(props)=> props.menuopen ? '0' : '-10px'};
        width: 2rem;
        height: .2rem;
        /* background-color: ${theme.SECONDARY_LIGHT}; */
        background:${(props)=> props.menuopen ? theme.WHITE : theme.PRIMARY};
        transition: ${(props)=> props.menuopen ? '.45s' : ' .15s'};
    }
`

export const LineBox = styled(motion.div)`
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    top: 1rem;
    right:2rem;
    position: absolute;
    :hover ${Lines}{
        opacity: .5;
        transition: .18s;
    }
    :hover ${Lines}::after{
        transform: ${(props)=> props.menuopen ? 'scale(1.05) rotate(-135deg)' : 'scale(1.05) rotate(-2deg)'};
        transition: ${(props)=> props.menuopen ? '.45s' : ' .15s'};
    }
    :hover ${Lines}::before{
        transform: ${(props)=> props.menuopen ? 'scale(1.05) rotate(-45deg)' : ' scale(1.1) rotate(4deg)'};
        transition: ${(props)=> props.menuopen ? '.45s' : ' .15s'};
    }
`



export const Links = styled(motion.div)`
    background: ${theme.PRIMARY};
    top: 0;
    /* display: flex; */
    display: ${(props)=> props.menuopen ? 'flex' : 'none'};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    align-items: center;
    /* justify-content: space-around; */
    padding: 1rem 5rem;
    z-index: 9000;
`;

export const MenuText = styled.p`
    color:${theme.SECONDARY};
    letter-spacing: .7rem;
    margin-bottom: 1rem;

`

export const LinkTitle = styled(motion.h1)`
    color: ${theme.LIGHT_GRAY};
    font-family: 'Helvetica-ExtraBlack-Italic';
    font-size: 5.5rem;
    transition: all .15s;
    :hover{
        color: ${theme.WHITE};
        transition: all .15s;
        letter-spacing: 1.1rem;
    }
    @media (max-width: 1000px) {
        font-size: 4.5rem;
    }
    @media (max-width: 650px) {
        font-size: 3.5rem;
    }
`

export const Footer = styled.p`
    position: absolute;
    bottom: 1rem;
    color: ${theme.SECONDARY_LIGHT};
    transform: scale(.8);
`


