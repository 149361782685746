import svAbout from '../images/my-image-min.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HiOutlineDocumentText } from 'react-icons/hi'
import { faFacebook, faGithubAlt, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { AboutSection, 
    About, 
    Description, 
    Name, 
    Title, 
    Paragraph, 
    Image, 
    HeroPoster, 
    PatternBackground, 
    SocialIconsContainer, 
    SocialIcons, 
    Line, 
    LinkButton } from '../styles/AboutMeStyles'
import { titleAnimation, fade, buttonAnimation, patterAnimation, heroAnimation } from '../animation/homepage'

import scrollImg from '../images/scroll.gif'

const socials = [
    {
        name:"Github",
        link:"https://github.com/sahdev50",
    },
    {
        name:"Instagram",
        link:"https://www.instagram.com/sahdev_odedara/"
    },
    {
        name:"LinkedIn",
        link:"https://www.linkedin.com/in/sahdevodedara/"
    },
    {
        name:"Facebook",
        link:"https://www.facebook.com/odedra.sahdev"
    }

]

const AboutMeSection = ()=>{
    return(
        <AboutSection id='about' >
            <img className='scrollAnim' src={scrollImg}  alt="scroll anim"></img>
            <About>
                <Description variants={fade} animate="show" initial="hidden" >
                    <div>
                        <div className="hide">
                            <p>Hi, i'm</p>
                        </div>
                        <div className="hide">
                            <Name variants={titleAnimation} >Sahdev Odedara</Name>
                        </div>
                        <div className="hide">
                            <Title variants={titleAnimation}>Full Stack Developer</Title>
                        </div>
                    </div>
                    <div className="about">
                        <Paragraph variants={titleAnimation} >
                            As a Full-Stack Developer with 2+ years of experience, I possess a broad range of 
                            technical skills and expertise in web development. I have experience working on both 
                            the front-end and back-end of web applications
                        </Paragraph>
                        {/* <a href={MyResume} download="Sahdev_Odedara" target='_blank' rel="noreferrer">
                            <Button whileHover={{scale:1.1}} whileTap={{scale:0.9}} variants={buttonAnimation} >My Resume <HiOutlineDocumentText size="20px"/></Button>
                        </a> */}
                        <div className='otherlinks'>
                            <HashLink smooth to="/#project">
                                <LinkButton>View Projects</LinkButton>    
                            </HashLink> <span>Or</span> <HashLink smooth to="/#resume">
                                <LinkButton>Read About Me</LinkButton>
                            </HashLink>
                        </div>
                    </div>
                </Description>
                <Image variants={fade} animate="show" initial="hidden" >
                    <HeroPoster  variants={heroAnimation} src={svAbout} alt='sv-about' loading='lazy'/>
                    <PatternBackground variants={patterAnimation} />
                </Image>
            </About>
            <SocialIconsContainer variants={fade} animate="show" initial="hidden">
                <SocialIcons variants={patterAnimation}>
                    <Line />
                    <Link className='social-linked' to={socials[0].link} target='_blank'><FontAwesomeIcon icon={faInstagram} size='xl' color='#343a40' /></Link>
                    <Link className='social-linked' to={socials[1].link} target='_blank'><FontAwesomeIcon icon={faLinkedin} size='xl' color='#343a40'/></Link>
                    <Link className='social-linked' to={socials[2].link} target='_blank'><FontAwesomeIcon icon={faGithubAlt} size='xl' color='#343a40'/></Link>
                    <Link className='social-linked' to={socials[3].link} target='_blank'><FontAwesomeIcon icon={faFacebook} size='xl' color='#343a40'/></Link>
                    <Line />
                </SocialIcons>
            </SocialIconsContainer>
        </AboutSection>
    )
}

export default AboutMeSection