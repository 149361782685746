import React from 'react'
import { Frame1, Frame2 } from '../styles/GlobalStyles'

const transition = (AppComponent) => {
  return ()=> (
    <>
        <AppComponent />
        <Frame1 
            initial={{ scaleY:0 }}
            animate={{scaleY:0}}
            exit={{scaleY:1}}
            transition={{duration:1, ease:[.22, 1, .36, 1]}}
        />
        <Frame2 
            initial={{ scaleY:1 }}
            animate={{scaleY:0}}
            exit={{scaleY:0}}
            transition={{duration:1, ease:[.22, 1, .36, 1]}}
        />
    </>
  )
}

export default transition