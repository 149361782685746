import styled from "styled-components"
import * as theme from "./variables"
import { motion } from "framer-motion"

export const ProjectSectionDiv = styled(motion.div)`
    width: 100%;
    height: fit-content;
    margin-bottom: 10rem;
    padding:0 5rem;
    display: flex;
    flex-direction:column;
    margin-bottom:1rem;
    @media (max-width: 1300px) {
        margin-bottom:2rem;
        padding:0 2rem;
    }
    @media (max-width: 1010px) {
        margin-bottom:2rem;
        padding:0 1rem;
    }
    
`

export const Name = styled.h3`
    font-size:.9rem;
    margin:.5rem 0;
    font-family:'Helvetica-Extra-Light';
    letter-spacing:.35rem;
    text-transform: uppercase;
    color: ${theme.SECONDARY};
    @media (max-width: 650px) {
        font-size:.7rem;
    }
`

export const Title = styled.h1`
    font-size:4.5rem;
    position: relative;
    left: -.5%;
    font-family: 'Helvetica-ExtraBold';
    margin: 1rem 0;
    color:${theme.PRIMARY};
    @media (max-width: 1010px) {
        font-size:3.5rem;
    }
    @media (max-width: 710px) {
        font-size:2.5rem;
    }
`

export const ProjectsCards = styled(motion.div)`
    width: 100%;
    display: flex;
    height:fit-content;
    flex-direction: column;
    /* cursor: pointer; */
`
export const FirstContainer = styled(motion.div)`
    width: 100%;
    height: 700px;
    display: flex;
    overflow: hidden !important;
    justify-content: space-evenly;
    margin-bottom: 3rem;
    @media (max-width: 1010px) {
        flex-direction: column;
        height: 100%;
        margin-bottom: 0rem;
    }
`

export const SecondContainer = styled(motion.div)`
    width: 100%;
    display: flex;
    height: 700px;
    overflow: hidden !important;
    justify-content: space-evenly;
    margin-bottom: 3rem;
    @media (max-width: 1010px) {
        flex-direction: column;
        height: 100%;
    }
`

export const ThirdContainer = styled(motion.div)`
    width: 100%;
    display: flex;
    height: 700px;
    overflow: hidden !important;
    justify-content: space-evenly;
    @media (max-width: 1010px) {
        flex-direction: column;
        height: 100%;
    }
`

export const Poster = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1;
    filter: grayscale(50%);
    transition: .2s;
`

export const Card = styled(motion.div)`
    /* width: 100%; */
    display: inline-block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: 600px;
    height: 700px;
    @media (max-width: 1280px) {
        width: 500px;
        height: 650px;
        margin-bottom: 2rem;
    }
    @media (max-width: 1100px) {
        width: 450px;
        height: 600px;
        margin-bottom: 2rem;
    }
    @media (max-width: 1010px) {
        width: 100%;
        height: 600px;
        margin-bottom: 2rem;
    }
    @media (max-width: 700px) {
        height: 600px;
    }
    &:hover ~ ${Poster}{
        scale: 1.2;
        rotate: 2deg;
    }
`

export const TextBoxStyles = styled(motion.div)`
    z-index: 1;
    position: absolute;
    padding: 2rem;
    `
export const CardTitleStyles = styled.h3`
z-index:10;
width: 70%;
margin-top: .8rem;
font-family: 'Helvetica-Bold';
color: ${theme.WHITE};
font-size:2rem;
text-shadow: ${theme.PRIMARY} 1px 1px 1px;
transition: 0.5s;
@media (max-width: 1010px) {
    font-size:1.6rem;
    width: 80%;
}
@media (max-width: 710px) {
    font-size:1.4rem;
    width: 100%;
}
`

export const Layer = styled(motion.div)`
width: 100%;
height: 100%;
position: absolute;
z-index: 0;
top: 0;
background: linear-gradient(124deg, rgba(6,6,6,0.902) 0%, rgba(7,7,7,0.435) 50%, rgba(131,131,131,0) 100%);
backdrop-filter: grayscale(50%);
transition: 0.15s;
    &:hover{
        filter: grayscale(100%);
    }
    &:hover ~ ${Poster}{
        scale: 1.2;
        rotate: 2deg;
    }
    `

export const TextBox = styled(TextBoxStyles)`
    &:hover ~ ${Layer}{
        filter: grayscale(100%);
    }
    &:hover ~ ${Poster}{
        scale: 1.2;
        rotate: 2deg;
    }
`

export const CardYear = styled.p`
    z-index:10;
    font-family: 'Helvetica-Medium-Italic';
    color: ${theme.LIGHT_GRAY};
    font-size:1.2rem;
    &::before{
        content: "- ";
    }
    &::after{
        content: " -";
    }
`

export const CardTitle = styled(CardTitleStyles)`
    &:hover ~ ${Layer}{
        filter: grayscale(100%);
    }
    &:hover ~ ${Poster}{
        scale: 1.2;
        rotate: 2deg;
    }
`