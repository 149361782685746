export const fade = {
    hidden:{
        scale:1.5, opacity:0
    },
    show:{scale:1, opacity:1,transition:{duration:.75,type: "ease", staggerChildren:.45, delay:.5}}
}

export const titleAnimation = {
    hidden:{x:-100, opacity:0},
    show:{x:0, opacity:1, transition:{duration:.75,type: "ease"}}
}

export const buttonAnimation = {
    hidden:{y:20, opacity:0},
    show:{y:0, opacity:1, transition:{duration:1,type: "spring",}}
}

export const patterAnimation = {
    hidden:{x: 100, opacity:0,},
    show:{x: 0, opacity:1, transition:{duration:1.5,type: "spring", delay:.7}}
}

export const heroAnimation = {
    hidden:{y: 1000, opacity:0, scale:.1},
    show:{y: 0, opacity:1, scale:1,transition:{duration:1,type: "spring", delay:.8}}
}
