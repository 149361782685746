import React from 'react'
import footerlogo from '../images/main-logo.png'
import MyResume from '../utils/sahdev-odedara.pdf'
import {LinkButton} from '../styles/AboutMeStyles'
import { Container, Content, Text, Title, List, MainContainer, FooterLine, CopyrightText, FooterLogo, ListLink } from '../styles/Footer'

const socials = [
    {
        name:"Github",
        link:"https://github.com/sahdev50",
    },
    {
        name:"Instagram",
        link:"https://www.instagram.com/sahdev_odedara/"
    },
    {
        name:"LinkedIn",
        link:"https://www.linkedin.com/in/sahdevodedara/"
    },
    {
        name:"Facebook",
        link:"https://www.facebook.com/odedra.sahdev"
    }

]

const projects = [
    {
        link:"https://sahdevodedara50.com/",
        name:'Gazetteer'
    },
    {
        link:"http://company-directory.great-site.net/?i=1",
        name:"Company Directory"
    },
]


function Footer() {
  return (
    <MainContainer>
        <FooterLine />
        <Container>
        <Content>
            <Title>Contact Me</Title>
            <Content>
                <Text>Feel free to reach out to me any time. I prefer to talk over email,<br/>especially since we may be a few time zones away.</Text>
                <div>
                    
                    <a href={MyResume} download="Sahdev_Odedara" target='_blank' rel="noreferrer">
                        <LinkButton>Download CV</LinkButton>
                    </a>
                </div>
            </Content>
            <FooterLogo src={footerlogo} width={200} />
        </Content>
        <Content>
            <Title>Socials</Title>
            <ul>
                {socials.map((data, index)=>(
                    <List key={index} >
                        <ListLink href={data.link} target='_blank'>{data.name}</ListLink>
                    </List>
                ))}
            </ul>
        </Content>
        <Content>
            <Title>Projects</Title>
            <ul>
                {projects.map((data, index)=>(
                    <List key={index} >
                        <ListLink href={data.link} target='_blank'>{data.name}</ListLink>
                    </List>
                ))}
            </ul>
        </Content>
    </Container>
    <CopyrightText>&copy;{new Date().getFullYear()}  Sahdev V. Odedara - Dublin,Ireland</CopyrightText>
    </MainContainer>
  )
}

export default Footer